/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import {
  AlertController,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { ProfileService } from '../profile/profile.service';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
@Injectable({
  providedIn: 'root',
})
export class HelperService {

  apiAPP: any = 'Tidak tersedia';

  // Config version API
  ec2 = `http://52.220.219.235:9533/`;
  lightsail = `http://18.138.89.222:9533/`;
  digitalocean = `https://gadai.nirmalab.com/`;
  apiVersion = 'api-v1'; // version

  // Pagination Size
  public size = 10;
  public timeout = 10 * 1000; //Timeout 10 detik

  public apiAPKVersion = `${this.digitalocean}gadai-version/gadai-digital.apk`;
  public apiEndpoint = this.digitalocean + this.apiVersion;
  public apiWhatsapp = `https://gadai-wa.herokuapp.com/`;
  // public apiEndpoint = `http://localhost:9533/${this.version}`;

  // Pagination Size
  public size_wilayah = 9999;
  public size_cabang = 9999;
  public size_kategori = 9999;


  constructor(
    private platform: Platform,
    private http: HttpClient,
    private appVersion: AppVersion,
    private toastController: ToastController,
    private navCtrl: NavController,
    private alertController: AlertController,
    private profileService: ProfileService // private appUpdate: AppUpdate
  ) { }


  public async webVersion() {
    const result = (await Device.getInfo()).webViewVersion;
    return result;
  }

  public async checkVersion() {
    if (!this.platform.is('android') || this.platform.is('mobileweb')) { return 'Web' } //if not an android
    const version = ((await App.getInfo()).version);
    const build = ((await App.getInfo()).build);
    return `v. ${version} | Build ${build}`;
  }

  getDevice() {
    return new Promise(async (resolve, reject) => {
      this.appVersion
        .getVersionNumber()
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getVersion() {
    if (this.platform.is('capacitor')) {
      this.getDevice()
        .then((res) => {
          console.log(res);
          this.apiAPP = res;
        })
        .catch((err) => {
          console.log(err);
          this.apiAPP = 'Tidak tersedia';
        });
    } else {
      this.apiAPP = 'Web';
    }
  }

  async checkExpire() {
    const isExpired = this.profileService.isExpired();
    const isLogin = this.profileService.isLogin();

    if (isLogin) {
      if (isExpired) {
        localStorage.clear();
        this.errorToast({
          message: 'Sesi telah habis silakan login lagi.',
          position: 'bottom',
        });
        this.navCtrl.navigateRoot('/welcome');
        return true;
      }
    }
    return false;
  }

  async getVersionAPI() {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiEndpoint}/app/version`).subscribe(
        (result) => {
          resolve(result);
          console.log('service success', result);
        },
        (err) => {
          reject(err?.message);
          console.log('service oops', err);
        }
      );
    });
  }

  async invalidVersion() {
    const alert = await this.alertController.create({
      header: 'Oops!',
      message: 'Aplikasi yang anda pakai versi lama, mohon pakai yang terbaru!',
      backdropDismiss: false,
      cssClass: 'alert-apps',
      mode: 'ios',
      buttons: [
        {
          role: 'cancel',
          text: 'Unduh Aplikasi Terbaru',
          handler: () => {
            this.downloadApp();
          },
        },
        {
          text: 'Keluar',
          role: 'oke',
          handler: () => {
            localStorage.clear();
            navigator['app'].exitApp();
          },
        },
      ],
    });
    alert.present();
  }

  async checkUpdate(msg?) {
    if (!this.platform.is('android') || this.platform.is('mobileweb')) {
      this.presentToast({ message: 'Mohon gunakan mobile atau android!' })
      return;
    } //if not an android

    this.getVersionAPI()
      .then(async (res: any) => {
        const cloud_version = res.data[0].appversion;
        const current_version = ((await App.getInfo()).version);

        if (current_version < cloud_version) { this.invalidVersion(); return }
        this.presentToast({ message: 'Aplikasi menggunakan versi terbaru!' });;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async checkUpdateWebview(msg?) {
    return this.getVersionAPI()
      .then(async (res: any) => {
        const cloud_version = res.data[0].webviewversion;
        const current_version = (await Device.getInfo()).webViewVersion;

        if (parseFloat(current_version) < parseFloat(cloud_version)) {
          return true;
          // this.errorToast({ message: 'Disarankan untuk menggunakan versi webview terbaru.' });;
        }
        return false;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  downloadApp() {
    const url = this.apiAPKVersion;
    window.open(url, '_blank');
  }

  async presentToast(data) {
    const toast = await this.toastController.create({
      header: data?.header,
      message: data?.message,
      mode: 'ios',
      color: data?.color ? data.color : 'success',
      position: data?.position ? data.position : 'bottom',
      duration: 3000,
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-done-outline',
          handler: () => {
            console.log('Favorite clicked');
          },
        },
        {
          role: 'cancel',
          icon: 'close',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  async errorToast(data) {
    // console.log(data)
    const toast = await this.toastController.create({
      mode: 'ios',
      header: data?.header,
      message: data?.message,
      color: 'danger',
      position: data.position ? data.position : 'bottom',
      duration: 2000,
      buttons: [
        {
          side: 'start',
          icon: 'warning',
          handler: () => {
            console.log('Favorite clicked');
          },
        },
        {
          role: 'cancel',
          icon: 'close',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
    });
    await toast.present();
  }
}
