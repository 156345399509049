import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  playerid: any;

  constructor() { }

  setUser(data) {
    localStorage.setItem('user', JSON.stringify(data));
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  getToken() {
    return this.getUser()?.token;
  }

  getRole() {
    return this.getUser()?.role;
  }

  getName() {
    return this.getUser()?.nama;
  }

  getID() {
    console.log(this.getUser)
    return this.getUser()?.id;
  }

  getNamaWil() {
    return this.getUser()?.namawil;
  }

  getIDWil() {
    return this.getUser()?.idwil;
  }

  getExpire() {
    return this.getUser()?.exp;
  }

  isAdmin() {
    return this.getRole() === 'admin';
  }

  isBroadcaster() {
    return this.getRole() === 'broadcaster';
  }

  isExpired() {
    // Ubah tanggal jadi 24+ bukan 01 PM/AM tapi 13:00
    // console.log(Date.now(), Date.parse('2022-04-07 12:45'), Date.parse('2022-04-07 13:12'), Date.parse('2022-04-07 01:12'))
    return Date.now() > Date.parse(this.getExpire()) ? true : false;
  }

  isLogin() {
    return this.getUser() === undefined || this.getUser() === null ? false : true;
  }

  setPlayerID(val) {
    this.playerid = val;
  }

  getPlayerID() {
    return this.getUser()?.playerid || this.playerid;
  }
}
