import { Component } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { HelperService } from './service/helper/helper.service';
import { ProfileService } from './service/profile/profile.service';
import { App } from '@capacitor/app';
import OneSignal from 'onesignal-cordova-plugin';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private navCtrl: NavController,
    private platform: Platform,
    private helper: HelperService,
    private profileService: ProfileService // private oneSignal: OneSignal
  ) {
    // Hadware back button ionic
    App.addListener('backButton', ({ canGoBack }) => {
      if (!canGoBack) { App.exitApp(); }
    });

    if (this.platform.is('capacitor')) {
      console.log('OneSignal');
      this.oneSignalInit();
      this.initVersion();
    }

    console.log(
      'Status Expired',
      this.profileService.isExpired(),
      this.profileService.getUser()
    );

    if (!this.helper.checkExpire()) {
      this.navCtrl.navigateRoot('/tabs/tab1');
    }

    if (this.profileService.getUser() == null) {
      this.navCtrl.navigateRoot('/welcome');
    }
  }

  initVersion() {
    this.helper.getVersion(); //Trigger
    this.helper.checkUpdate();
    console.log('Get Version also check version');
  }

  // Call this function when your app starts
  oneSignalInit(): void {
    // NOTE: Update the setAppId value below with your OneSignal AppId.
    OneSignal.setAppId('422b8aae-4454-4c2d-a846-a47ebc3a4fcc');
    OneSignal.setNotificationOpenedHandler((jsonData) => {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });

    OneSignal.getDeviceState((stateChanges) => {
      console.log('OneSignal getDeviceState: ' + JSON.stringify(stateChanges));
      this.profileService.setPlayerID(stateChanges.userId);
    });
  }
}
