import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, TimeoutError, throwError } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HelperService } from './service/helper/helper.service';
import { ProfileService } from './service/profile/profile.service';
import { NavController } from '@ionic/angular';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private helperService: HelperService,
    private profileService: ProfileService,
    private navCtrl: NavController
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Mending dibuat pengecekan token saja.
    this.helperService.checkExpire();

    const token = this.profileService.getToken();

    //Authentication by setting header with token value
    if (token) {
      request = request.clone({
        setHeaders: {
          token,
        },
      });
    }
    return next.handle(request).pipe(
      timeout(this.helperService.timeout),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        if (error instanceof TimeoutError) {
          this.helperService.errorToast({
            message: 'Koneksi timeout, pastikan jaringanmu sudah stabil.',
            position: 'bottom',
          });
        }
        if (error.status === 0) {
          if (error.statusText === 'Unknown Error') {
            this.helperService.errorToast({
              message: 'Koneksi tidak stabil, mohon periksa kembali jaringanmu.',
              position: 'top',
            });
          }
        }
        if (error.statusText === 'TimeoutError') {
          return throwError({
            error: { msg: 'Koneksi internet bermasalah' },
          });
        }
        if (error.status === 401) {
          this.helperService.errorToast({
            message: error.error.msg,
            position: 'top',
          });
          if (error.error.success === false) {
            console.log('Interceptor', error);
          } else {
            console.log('Interceptor', error);
            localStorage.clear();
            this.navCtrl.navigateRoot('/welcome', { animated: true });
          }
        }
        this.helperService.errorToast({
          message: error.error.msg || 'Sepertinya ada yang salah',
          position: 'top',
        });
        return throwError(error);
      })
    );
  }
}
