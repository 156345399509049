import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./page/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'signin',
    loadChildren: () => import('./page/signin/signin.module').then( m => m.SigninPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./page/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'kategori',
    loadChildren: () => import('./page/kategori/kategori.module').then( m => m.KategoriPageModule)
  },
  {
    path: 'wilayah',
    loadChildren: () => import('./page/wilayah/wilayah.module').then( m => m.WilayahPageModule)
  },
  {
    path: 'customer',
    loadChildren: () => import('./page/customer/customer.module').then( m => m.CustomerPageModule)
  },
  {
    path: 'transaksi',
    loadChildren: () => import('./page/transaksi/transaksi.module').then( m => m.TransaksiPageModule)
  },
  {
    path: 'users-crud',
    loadChildren: () => import('./page/users-crud/users-crud.module').then( m => m.UsersCrudPageModule)
  },
  {
    path: 'customer-crud',
    loadChildren: () => import('./page/customer-crud/customer-crud.module').then( m => m.CustomerCrudPageModule)
  },
  {
    path: 'kategori-crud',
    loadChildren: () => import('./page/kategori-crud/kategori-crud.module').then( m => m.KategoriCrudPageModule)
  },
  {
    path: 'transaksi-crud',
    loadChildren: () => import('./page/transaksi-crud/transaksi-crud.module').then( m => m.TransaksiCrudPageModule)
  },
  {
    path: 'transaksi-detail',
    loadChildren: () => import('./page/transaksi-detail/transaksi-detail.module').then( m => m.TransaksiDetailPageModule)
  },
  {
    path: 'customer-detail',
    loadChildren: () => import('./page/customer-detail/customer-detail.module').then( m => m.CustomerDetailPageModule)
  },
  {
    path: 'transaksi-kategori',
    loadChildren: () => import('./page/transaksi-kategori/transaksi-kategori.module').then( m => m.TransaksiKategoriPageModule)
  },
  {
    path: 'wilayah-crud',
    loadChildren: () => import('./page/wilayah-crud/wilayah-crud.module').then( m => m.WilayahCrudPageModule)
  },
  {
    path: 'transaksi-done',
    loadChildren: () => import('./page/transaksi-done/transaksi-done.module').then( m => m.TransaksiDonePageModule)
  },  {
    path: 'signup',
    loadChildren: () => import('./page/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'filter-lelang',
    loadChildren: () => import('./page/filter-lelang/filter-lelang.module').then( m => m.FilterLelangPageModule)
  },
  {
    path: 'users-pw',
    loadChildren: () => import('./page/users-pw/users-pw.module').then( m => m.UsersPwPageModule)
  },
  {
    path: 'waurl',
    loadChildren: () => import('./page/waurl/waurl.module').then( m => m.WaurlPageModule)
  },
  {
    path: 'webview',
    loadChildren: () => import('./page/webview/webview.module').then( m => m.WebviewPageModule)
  },
  {
    path: 'barang',
    loadChildren: () => import('./page/barang/barang.module').then( m => m.BarangPageModule)
  },
  {
    path: 'barang-crud',
    loadChildren: () => import('./page/barang-crud/barang-crud.module').then( m => m.BarangCrudPageModule)
  },




];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
